import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgCB from "../../assets/image/png/construction.png";
import iconC1 from "../../assets/image/l7/png/content1-icon1.png";
import iconC2 from "../../assets/image/l7/png/content1-icon2.png";
import checkIcon from "../../assets/image/svg/check.svg";

const ICON_HEIGHT = "24px";
const ICON_WIDTH = "28px";
const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section position-relative overflow-hidden pt-15 pb-11 pb-lg-25 pt-lg-27 bg-default-2 rounded-5">
        <Container>
          <Row className="align-items-center justify-content-md-center">
            <Col sm="10" lg="5" className="position-static">
              <div className="content-img position-relative gr-abs-lg gr-abs-lg-ly-center w-lg-50 text-center text-lg-left">
                <img
                  className="h-100 w-100 w-lg-75 rounded-5"
                  src={imgCB}
                  alt=""
                />
              </div>
            </Col>
            <Col sm="11" lg="7">
              <Row>
                <Col lg="10" xl="12">
                  <div className="content-text mb-9 mb-lg-17 mb-xl-22 mt-11 mt-lg-0">
                    <h2 className="title gr-text-3 mb-11">
                      Elegant & simplistic incorporation in composites &
                      laminates.
                    </h2>
                    <p className="gr-text-8 mb-5 pr-11">
                      Due to the incredible properties of the lightweight
                      non-Newtonian foam polymer, only small amounts need be
                      added to see dramatic improvements in the damping
                      capability of the craft for high frequency vibration &
                      reduced resonance.
                    </p>
                  </div>
                </Col>
                <Col xs="12">
                  <div className="features-wrapper mt-4 mt-md-0">
                    <Row>
                      <Col md="12">
                        <div
                          className="single-feature media py-6"
                          data-aos="fade-left"
                          data-aos-duration="800"
                        >
                          <div className="media-icon mr-6">
                            <img
                              src={checkIcon}
                              alt=""
                              height={ICON_HEIGHT}
                              width={ICON_WIDTH}
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="title gr-text-7 mb-6">
                              Use industry standard construction methods.
                            </h3>
                            <p className="gr-text-9 mb-0 pr-xl-6">
                              Compatible with composites, epoxy and glulam
                              construction.
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div
                          className="single-feature media py-6"
                          data-aos="fade-left"
                          data-aos-duration="1200"
                        >
                          <div className="media-icon mr-6">
                            <img
                              src={checkIcon}
                              alt=""
                              height={ICON_HEIGHT}
                              width={ICON_WIDTH}
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="title gr-text-7 mb-6">
                              Maintain structural integrity & flex
                              characteristics.
                            </h3>
                            <p className="gr-text-9 mb-0 pr-xl-6">
                              Minimal modification to existing designs required.
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col md="12">
                        <div
                          className="single-feature media py-6"
                          data-aos="fade-left"
                          data-aos-duration="1200"
                        >
                          <div className="media-icon mr-6">
                            <img
                              src={checkIcon}
                              alt=""
                              height={ICON_HEIGHT}
                              width={ICON_WIDTH}
                            />
                          </div>
                          <div className="media-body">
                            <h3 className="title gr-text-7 mb-6">
                              Lightweight material
                            </h3>
                            <p className="gr-text-9 mb-0 pr-xl-6">
                              Achieve the desired overall properties through use
                              of various density dilatant (non-Newtonian)
                              polymers.
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
