import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { VictoryLine, VictoryChart, VictoryAxis } from "victory";
import theme from "../../utils/theme";

const Data = () => (
  <>
    {/* <!-- Content section --> */}
    <div className="content-section bg-gradient pt-lg-25 pt-14 pb-10 pb-lg-15">
      <Container>
        <Row className="justify-content-center">
          <Col md="8">
            <div className="section-title mb-13 ">
              <h2 className="title gr-text-3 mb-7 text-center">
                Scientifically tested and analyzed.
                <br />
                Ready for product development.
              </h2>
              <p className="gr-text-6 text-center">
                Measured 80% increase in damping ratios even at low speeds.
              </p>
            </div>
            <div className="image-left">
              <VictoryChart
                animate={{
                  duration: 1500,
                  onEnter: { duration: 500 },
                }}
              >
                <VictoryAxis tickLabelComponent={<></>} axisComponent={<></>} />
                <VictoryAxis
                  dependentAxis
                  tickLabelComponent={<></>}
                  axisComponent={<></>}
                />
                <VictoryLine
                  data={data2}
                  interpolation="natural"
                  x="num"
                  y="g28"
                  style={{
                    data: {
                      strokeWidth: 1,
                      stroke: theme.colors.primary,
                    },
                  }}
                />
                <VictoryLine
                  data={data1}
                  x="num"
                  y="a28"
                  style={{
                    data: {
                      strokeWidth: 0.5,
                      stroke: theme.colors.dark,
                    },
                  }}
                />
              </VictoryChart>
            </div>
            <p className="gr-text-9 pb-8 pt-8 text-center">
              Actual test data measuring damped vibrations over time.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

const data1 = [
  { num: 1, a28: 0 },
  { num: 2, a28: 0 },
  { num: 3, a28: 0 },
  { num: 4, a28: 0 },
  { num: 5, a28: 0 },
  { num: 6, a28: 0 },
  { num: 7, a28: 0 },
  { num: 8, a28: 0 },
  { num: 9, a28: 0 },
  { num: 10, a28: 0 },
  { num: 11, a28: -1 },
  { num: 12, a28: -1 },
  { num: 13, a28: -1 },
  { num: 14, a28: -1 },
  { num: 15, a28: 0 },
  { num: 16, a28: 0 },
  { num: 17, a28: 0 },
  { num: 18, a28: -1 },
  { num: 19, a28: 0 },
  { num: 20, a28: 0 },
  { num: 21, a28: 1 },
  { num: 22, a28: 1 },
  { num: 23, a28: 2 },
  { num: 24, a28: 1 },
  { num: 25, a28: 1 },
  { num: 26, a28: 0 },
  { num: 27, a28: 4 },
  { num: 28, a28: 2 },
  { num: 29, a28: 4 },
  { num: 30, a28: 3 },
  { num: 31, a28: 3 },
  { num: 32, a28: 9 },
  { num: 33, a28: 22 },
  { num: 34, a28: 14 },
  { num: 35, a28: 14 },
  { num: 36, a28: 15 },
  { num: 37, a28: 20 },
  { num: 38, a28: 29 },
  { num: 39, a28: 36 },
  { num: 40, a28: 23 },
  { num: 41, a28: 1 },
  { num: 42, a28: -33 },
  { num: 43, a28: -59 },
  { num: 44, a28: -79 },
  { num: 45, a28: -74 },
  { num: 46, a28: -64 },
  { num: 47, a28: -31 },
  { num: 48, a28: -1 },
  { num: 49, a28: 32 },
  { num: 50, a28: 63 },
  { num: 51, a28: 68 },
  { num: 52, a28: 76 },
  { num: 53, a28: 50 },
  { num: 54, a28: 23 },
  { num: 55, a28: -6 },
  { num: 56, a28: -36 },
  { num: 57, a28: -51 },
  { num: 58, a28: -62 },
  { num: 59, a28: -54 },
  { num: 60, a28: -39 },
  { num: 61, a28: -14 },
  { num: 62, a28: 15 },
  { num: 63, a28: 35 },
  { num: 64, a28: 53 },
  { num: 65, a28: 56 },
  { num: 66, a28: 48 },
  { num: 67, a28: 26 },
  { num: 68, a28: 3 },
  { num: 69, a28: -19 },
  { num: 70, a28: -39 },
  { num: 71, a28: -45 },
  { num: 72, a28: -47 },
  { num: 73, a28: -35 },
  { num: 74, a28: -15 },
  { num: 75, a28: 5 },
  { num: 76, a28: 27 },
  { num: 77, a28: 39 },
  { num: 78, a28: 46 },
  { num: 79, a28: 41 },
  { num: 80, a28: 25 },
  { num: 81, a28: 6 },
  { num: 82, a28: -15 },
  { num: 83, a28: -29 },
  { num: 84, a28: -39 },
  { num: 85, a28: -40 },
  { num: 86, a28: -32 },
  { num: 87, a28: -15 },
  { num: 88, a28: 3 },
  { num: 89, a28: 20 },
  { num: 90, a28: 34 },
  { num: 91, a28: 39 },
  { num: 92, a28: 36 },
  { num: 93, a28: 24 },
  { num: 94, a28: 6 },
  { num: 95, a28: -10 },
  { num: 96, a28: -25 },
  { num: 97, a28: -32 },
  { num: 98, a28: -35 },
  { num: 99, a28: -27 },
  { num: 100, a28: -15 },
  { num: 101, a28: 1 },
  { num: 102, a28: 20 },
  { num: 103, a28: 27 },
  { num: 104, a28: 32 },
  { num: 105, a28: 30 },
  { num: 106, a28: 20 },
  { num: 107, a28: 6 },
  { num: 108, a28: -8 },
  { num: 109, a28: -20 },
  { num: 110, a28: -27 },
  { num: 111, a28: -28 },
  { num: 112, a28: -24 },
  { num: 113, a28: -12 },
  { num: 114, a28: 1 },
  { num: 115, a28: 13 },
  { num: 116, a28: 23 },
  { num: 117, a28: 27 },
  { num: 118, a28: 26 },
  { num: 119, a28: 17 },
  { num: 120, a28: 6 },
  { num: 121, a28: -6 },
  { num: 122, a28: -17 },
  { num: 123, a28: -23 },
  { num: 124, a28: -24 },
  { num: 125, a28: -20 },
  { num: 126, a28: -11 },
  { num: 127, a28: 0 },
  { num: 128, a28: 12 },
  { num: 129, a28: 20 },
  { num: 130, a28: 23 },
  { num: 131, a28: 21 },
  { num: 132, a28: 14 },
  { num: 133, a28: 5 },
  { num: 134, a28: -6 },
  { num: 135, a28: -15 },
  { num: 136, a28: -20 },
  { num: 137, a28: -20 },
  { num: 138, a28: -16 },
  { num: 139, a28: -8 },
  { num: 140, a28: 2 },
  { num: 141, a28: 10 },
  { num: 142, a28: 17 },
  { num: 143, a28: 19 },
  { num: 144, a28: 18 },
  { num: 145, a28: 11 },
  { num: 146, a28: 2 },
  { num: 147, a28: -7 },
  { num: 148, a28: -14 },
  { num: 149, a28: -17 },
  { num: 150, a28: -17 },
  { num: 151, a28: -13 },
  { num: 152, a28: -5 },
  { num: 153, a28: 4 },
  { num: 154, a28: 11 },
  { num: 155, a28: 15 },
  { num: 156, a28: 17 },
  { num: 157, a28: 14 },
  { num: 158, a28: 8 },
  { num: 159, a28: 0 },
  { num: 160, a28: -7 },
  { num: 161, a28: -13 },
  { num: 162, a28: -15 },
  { num: 163, a28: -14 },
  { num: 164, a28: -9 },
  { num: 165, a28: -2 },
  { num: 166, a28: 5 },
  { num: 167, a28: 11 },
  { num: 168, a28: 14 },
  { num: 169, a28: 13 },
  { num: 170, a28: 10 },
  { num: 171, a28: 4 },
  { num: 172, a28: -3 },
  { num: 173, a28: -8 },
  { num: 174, a28: -12 },
  { num: 175, a28: -12 },
  { num: 176, a28: -10 },
  { num: 177, a28: -5 },
  { num: 178, a28: 1 },
  { num: 179, a28: 7 },
  { num: 180, a28: 11 },
  { num: 181, a28: 12 },
  { num: 182, a28: 11 },
  { num: 183, a28: 7 },
  { num: 184, a28: 1 },
  { num: 185, a28: -5 },
  { num: 186, a28: -9 },
  { num: 187, a28: -11 },
  { num: 188, a28: -11 },
  { num: 189, a28: -8 },
  { num: 190, a28: -3 },
  { num: 191, a28: 3 },
  { num: 192, a28: 8 },
  { num: 193, a28: 11 },
  { num: 194, a28: 11 },
  { num: 195, a28: 8 },
  { num: 196, a28: 4 },
  { num: 197, a28: -1 },
  { num: 198, a28: -6 },
  { num: 199, a28: -9 },
  { num: 200, a28: -10 },
  { num: 201, a28: -9 },
  { num: 202, a28: -5 },
  { num: 203, a28: 0 },
  { num: 204, a28: 5 },
  { num: 205, a28: 9 },
  { num: 206, a28: 10 },
  { num: 207, a28: 10 },
  { num: 208, a28: 6 },
  { num: 209, a28: 1 },
  { num: 210, a28: -3 },
  { num: 211, a28: -8 },
  { num: 212, a28: -10 },
  { num: 213, a28: -10 },
  { num: 214, a28: -7 },
  { num: 215, a28: -3 },
  { num: 216, a28: 2 },
  { num: 217, a28: 6 },
  { num: 218, a28: 9 },
  { num: 219, a28: 9 },
  { num: 220, a28: 8 },
  { num: 221, a28: 4 },
  { num: 222, a28: 0 },
  { num: 223, a28: -5 },
  { num: 224, a28: -8 },
  { num: 225, a28: -9 },
  { num: 226, a28: -8 },
  { num: 227, a28: -5 },
  { num: 228, a28: -1 },
  { num: 229, a28: 3 },
  { num: 230, a28: 7 },
  { num: 231, a28: 8 },
  { num: 232, a28: 8 },
  { num: 233, a28: 6 },
  { num: 234, a28: 2 },
  { num: 235, a28: -2 },
  { num: 236, a28: -5 },
  { num: 237, a28: -7 },
  { num: 238, a28: -8 },
  { num: 239, a28: -6 },
  { num: 240, a28: -2 },
  { num: 241, a28: 1 },
  { num: 242, a28: 5 },
  { num: 243, a28: 7 },
  { num: 244, a28: 7 },
  { num: 245, a28: 6 },
  { num: 246, a28: 4 },
  { num: 247, a28: 0 },
  { num: 248, a28: -3 },
  { num: 249, a28: -6 },
  { num: 250, a28: -7 },
  { num: 251, a28: -6 },
  { num: 252, a28: -4 },
  { num: 253, a28: -1 },
  { num: 254, a28: 2 },
  { num: 255, a28: 5 },
  { num: 256, a28: 6 },
  { num: 257, a28: 6 },
  { num: 258, a28: 5 },
  { num: 259, a28: 2 },
  { num: 260, a28: -2 },
  { num: 261, a28: -4 },
  { num: 262, a28: -6 },
  { num: 263, a28: -6 },
  { num: 264, a28: -4 },
  { num: 265, a28: -2 },
  { num: 266, a28: 1 },
  { num: 267, a28: 4 },
  { num: 268, a28: 5 },
  { num: 269, a28: 6 },
  { num: 270, a28: 5 },
  { num: 271, a28: 3 },
  { num: 272, a28: 0 },
  { num: 273, a28: -3 },
  { num: 274, a28: -5 },
  { num: 275, a28: -6 },
  { num: 276, a28: -5 },
  { num: 277, a28: -3 },
  { num: 278, a28: 0 },
  { num: 279, a28: 2 },
  { num: 280, a28: 5 },
  { num: 281, a28: 6 },
  { num: 282, a28: 5 },
  { num: 283, a28: 4 },
  { num: 284, a28: 1 },
  { num: 285, a28: -1 },
  { num: 286, a28: -4 },
  { num: 287, a28: -5 },
  { num: 288, a28: -5 },
  { num: 289, a28: -4 },
  { num: 290, a28: -1 },
  { num: 291, a28: 1 },
  { num: 292, a28: 4 },
  { num: 293, a28: 5 },
  { num: 294, a28: 5 },
  { num: 295, a28: 4 },
  { num: 296, a28: 2 },
  { num: 297, a28: 0 },
  { num: 298, a28: -3 },
  { num: 299, a28: -4 },
  { num: 300, a28: -5 },
  { num: 301, a28: -4 },
  { num: 302, a28: -3 },
  { num: 303, a28: 0 },
  { num: 304, a28: 2 },
  { num: 305, a28: 4 },
  { num: 306, a28: 5 },
  { num: 307, a28: 4 },
  { num: 308, a28: 3 },
  { num: 309, a28: 1 },
  { num: 310, a28: -1 },
  { num: 311, a28: -3 },
  { num: 312, a28: -4 },
  { num: 313, a28: -4 },
  { num: 314, a28: -3 },
  { num: 315, a28: -1 },
  { num: 316, a28: 1 },
  { num: 317, a28: 3 },
  { num: 318, a28: 4 },
  { num: 319, a28: 4 },
  { num: 320, a28: 3 },
  { num: 321, a28: 2 },
  { num: 322, a28: -1 },
  { num: 323, a28: -2 },
  { num: 324, a28: -3 },
  { num: 325, a28: -4 },
  { num: 326, a28: -3 },
  { num: 327, a28: -1 },
  { num: 328, a28: 0 },
  { num: 329, a28: 2 },
  { num: 330, a28: 3 },
  { num: 331, a28: 4 },
  { num: 332, a28: 3 },
  { num: 333, a28: 2 },
  { num: 334, a28: 0 },
  { num: 335, a28: -2 },
  { num: 336, a28: -3 },
  { num: 337, a28: -4 },
  { num: 338, a28: -3 },
  { num: 339, a28: -2 },
  { num: 340, a28: 0 },
  { num: 341, a28: 2 },
  { num: 342, a28: 3 },
  { num: 343, a28: 4 },
  { num: 344, a28: 3 },
  { num: 345, a28: 2 },
  { num: 346, a28: 0 },
  { num: 347, a28: -1 },
  { num: 348, a28: -3 },
  { num: 349, a28: -3 },
  { num: 350, a28: -3 },
  { num: 351, a28: -2 },
  { num: 352, a28: -1 },
  { num: 353, a28: 1 },
  { num: 354, a28: 3 },
  { num: 355, a28: 3 },
  { num: 356, a28: 3 },
  { num: 357, a28: 3 },
  { num: 358, a28: 1 },
  { num: 359, a28: 0 },
  { num: 360, a28: -2 },
  { num: 361, a28: -3 },
  { num: 362, a28: -3 },
  { num: 363, a28: -2 },
  { num: 364, a28: -1 },
  { num: 365, a28: 0 },
  { num: 366, a28: 2 },
  { num: 367, a28: 3 },
  { num: 368, a28: 3 },
  { num: 369, a28: 3 },
  { num: 370, a28: 2 },
  { num: 371, a28: 0 },
  { num: 372, a28: -1 },
  { num: 373, a28: -2 },
  { num: 374, a28: -3 },
  { num: 375, a28: -3 },
  { num: 376, a28: -2 },
  { num: 377, a28: 0 },
  { num: 378, a28: 1 },
  { num: 379, a28: 2 },
  { num: 380, a28: 3 },
  { num: 381, a28: 3 },
  { num: 382, a28: 2 },
  { num: 383, a28: 1 },
  { num: 384, a28: -1 },
  { num: 385, a28: -2 },
  { num: 386, a28: -2 },
  { num: 387, a28: -3 },
  { num: 388, a28: -2 },
  { num: 389, a28: 0 },
  { num: 390, a28: 1 },
  { num: 391, a28: 2 },
  { num: 392, a28: 2 },
  { num: 393, a28: 3 },
  { num: 394, a28: 2 },
  { num: 395, a28: 1 },
  { num: 396, a28: 0 },
  { num: 397, a28: -1 },
  { num: 398, a28: -2 },
  { num: 399, a28: -2 },
  { num: 400, a28: -2 },
  { num: 401, a28: -1 },
  { num: 402, a28: 0 },
  { num: 403, a28: 1 },
  { num: 404, a28: 2 },
  { num: 405, a28: 2 },
  { num: 406, a28: 2 },
  { num: 407, a28: 2 },
  { num: 408, a28: 0 },
  { num: 409, a28: -1 },
  { num: 410, a28: -2 },
  { num: 411, a28: -2 },
  { num: 412, a28: -2 },
  { num: 413, a28: -1 },
  { num: 414, a28: 0 },
  { num: 415, a28: 1 },
  { num: 416, a28: 2 },
  { num: 417, a28: 2 },
  { num: 418, a28: 3 },
  { num: 419, a28: 2 },
  { num: 420, a28: 1 },
  { num: 421, a28: -1 },
  { num: 422, a28: -1 },
  { num: 423, a28: -2 },
  { num: 424, a28: -2 },
  { num: 425, a28: -2 },
  { num: 426, a28: -1 },
  { num: 427, a28: 0 },
  { num: 428, a28: 1 },
  { num: 429, a28: 2 },
  { num: 430, a28: 2 },
  { num: 431, a28: 2 },
  { num: 432, a28: 1 },
  { num: 433, a28: 0 },
  { num: 434, a28: -1 },
  { num: 435, a28: -2 },
  { num: 436, a28: -2 },
  { num: 437, a28: -2 },
  { num: 438, a28: -1 },
  { num: 439, a28: 0 },
  { num: 440, a28: 1 },
  { num: 441, a28: 2 },
  { num: 442, a28: 2 },
  { num: 443, a28: 2 },
  { num: 444, a28: 1 },
  { num: 445, a28: 0 },
  { num: 446, a28: -1 },
  { num: 447, a28: -1 },
  { num: 448, a28: -2 },
  { num: 449, a28: -2 },
  { num: 450, a28: -1 },
  { num: 451, a28: 0 },
  { num: 452, a28: 1 },
  { num: 453, a28: 1 },
  { num: 454, a28: 2 },
  { num: 455, a28: 2 },
  { num: 456, a28: 1 },
  { num: 457, a28: 0 },
  { num: 458, a28: 0 },
  { num: 459, a28: -1 },
  { num: 460, a28: -2 },
  { num: 461, a28: -1 },
  { num: 462, a28: -1 },
  { num: 463, a28: 0 },
  { num: 464, a28: 0 },
  { num: 465, a28: 1 },
  { num: 466, a28: 1 },
  { num: 467, a28: 2 },
  { num: 468, a28: 1 },
  { num: 469, a28: 1 },
  { num: 470, a28: 0 },
  { num: 471, a28: -1 },
  { num: 472, a28: -1 },
  { num: 473, a28: -2 },
  { num: 474, a28: -1 },
  { num: 475, a28: -1 },
  { num: 476, a28: 0 },
  { num: 477, a28: 1 },
  { num: 478, a28: 2 },
  { num: 479, a28: 2 },
  { num: 480, a28: 1 },
  { num: 481, a28: 1 },
  { num: 482, a28: 0 },
  { num: 483, a28: -1 },
  { num: 484, a28: -1 },
  { num: 485, a28: -1 },
  { num: 486, a28: -1 },
  { num: 487, a28: 0 },
  { num: 488, a28: 0 },
  { num: 489, a28: 0 },
  { num: 490, a28: 1 },
  { num: 491, a28: 2 },
  { num: 492, a28: 1 },
  { num: 493, a28: 1 },
  { num: 494, a28: 1 },
  { num: 495, a28: 0 },
  { num: 496, a28: -1 },
  { num: 497, a28: -1 },
  { num: 498, a28: -1 },
  { num: 499, a28: -1 },
  { num: 500, a28: 0 },
  { num: 501, a28: 1 },
  { num: 502, a28: 1 },
  { num: 503, a28: 1 },
  { num: 504, a28: 1 },
  { num: 505, a28: 1 },
  { num: 506, a28: 1 },
  { num: 507, a28: 0 },
  { num: 508, a28: -1 },
  { num: 509, a28: -1 },
  { num: 510, a28: -1 },
  { num: 511, a28: -1 },
  { num: 512, a28: 0 },
  { num: 513, a28: 0 },
  { num: 514, a28: 1 },
  { num: 515, a28: 1 },
  { num: 516, a28: 1 },
  { num: 517, a28: 1 },
  { num: 518, a28: 1 },
  { num: 519, a28: 0 },
  { num: 520, a28: -1 },
  { num: 521, a28: -1 },
  { num: 522, a28: -1 },
  { num: 523, a28: -1 },
  { num: 524, a28: -1 },
  { num: 525, a28: 0 },
  { num: 526, a28: 1 },
  { num: 527, a28: 1 },
  { num: 528, a28: 1 },
  { num: 529, a28: 1 },
  { num: 530, a28: 1 },
  { num: 531, a28: 1 },
  { num: 532, a28: 0 },
  { num: 533, a28: -1 },
  { num: 534, a28: -1 },
  { num: 535, a28: -1 },
  { num: 536, a28: -1 },
  { num: 537, a28: 0 },
  { num: 538, a28: 0 },
  { num: 539, a28: 1 },
  { num: 540, a28: 1 },
  { num: 541, a28: 1 },
  { num: 542, a28: 1 },
  { num: 543, a28: 0 },
  { num: 544, a28: 0 },
  { num: 545, a28: -1 },
  { num: 546, a28: -1 },
  { num: 547, a28: -1 },
  { num: 548, a28: -1 },
  { num: 549, a28: 0 },
  { num: 550, a28: 0 },
  { num: 551, a28: 1 },
  { num: 552, a28: 1 },
  { num: 553, a28: 1 },
  { num: 554, a28: 1 },
  { num: 555, a28: 0 },
  { num: 556, a28: 0 },
  { num: 557, a28: 0 },
  { num: 558, a28: -1 },
  { num: 559, a28: -1 },
  { num: 560, a28: -1 },
  { num: 561, a28: -1 },
  { num: 562, a28: 0 },
  { num: 563, a28: 1 },
  { num: 564, a28: 1 },
  { num: 565, a28: 1 },
  { num: 566, a28: 1 },
  { num: 567, a28: 1 },
  { num: 568, a28: 1 },
  { num: 569, a28: 0 },
  { num: 570, a28: -1 },
  { num: 571, a28: -1 },
  { num: 572, a28: -1 },
  { num: 573, a28: -1 },
  { num: 574, a28: 0 },
  { num: 575, a28: 0 },
  { num: 576, a28: 1 },
  { num: 577, a28: 1 },
  { num: 578, a28: 1 },
  { num: 579, a28: 1 },
  { num: 580, a28: 0 },
  { num: 581, a28: 0 },
  { num: 582, a28: -1 },
  { num: 583, a28: -1 },
  { num: 584, a28: -1 },
  { num: 585, a28: -1 },
  { num: 586, a28: 0 },
  { num: 587, a28: 0 },
  { num: 588, a28: 1 },
  { num: 589, a28: 1 },
  { num: 590, a28: 1 },
  { num: 591, a28: 1 },
  { num: 592, a28: 0 },
  { num: 593, a28: 0 },
  { num: 594, a28: 0 },
  { num: 595, a28: -1 },
  { num: 596, a28: -1 },
  { num: 597, a28: -1 },
  { num: 598, a28: 0 },
  { num: 599, a28: 0 },
  { num: 600, a28: 1 },
  { num: 601, a28: 1 },
  { num: 602, a28: 1 },
  { num: 603, a28: 1 },
  { num: 604, a28: 1 },
  { num: 605, a28: 0 },
  { num: 606, a28: 0 },
  { num: 607, a28: -1 },
  { num: 608, a28: -1 },
  { num: 609, a28: 0 },
  { num: 610, a28: -1 },
  { num: 611, a28: 0 },
  { num: 612, a28: 0 },
  { num: 613, a28: 0 },
  { num: 614, a28: 1 },
  { num: 615, a28: 1 },
  { num: 616, a28: 0 },
  { num: 617, a28: 0 },
  { num: 618, a28: 0 },
  { num: 619, a28: 0 },
  { num: 620, a28: 0 },
  { num: 621, a28: 0 },
  { num: 622, a28: -1 },
  { num: 623, a28: 0 },
  { num: 624, a28: 0 },
  { num: 625, a28: 1 },
  { num: 626, a28: 1 },
  { num: 627, a28: 1 },
  { num: 628, a28: 1 },
  { num: 629, a28: 1 },
  { num: 630, a28: 1 },
  { num: 631, a28: 0 },
  { num: 632, a28: -1 },
  { num: 633, a28: -1 },
  { num: 634, a28: -1 },
  { num: 635, a28: 0 },
  { num: 636, a28: 0 },
  { num: 637, a28: 1 },
  { num: 638, a28: 1 },
  { num: 639, a28: 1 },
  { num: 640, a28: 1 },
  { num: 641, a28: 0 },
  { num: 642, a28: 0 },
  { num: 643, a28: 0 },
  { num: 644, a28: 0 },
  { num: 645, a28: -1 },
  { num: 646, a28: -1 },
  { num: 647, a28: 0 },
  { num: 648, a28: 0 },
  { num: 649, a28: 0 },
  { num: 650, a28: 1 },
  { num: 651, a28: 1 },
  { num: 652, a28: 0 },
  { num: 653, a28: 1 },
  { num: 654, a28: 0 },
  { num: 655, a28: 0 },
  { num: 656, a28: 0 },
  { num: 657, a28: 0 },
  { num: 658, a28: 0 },
  { num: 659, a28: 0 },
  { num: 660, a28: 0 },
  { num: 661, a28: 0 },
  { num: 662, a28: 1 },
  { num: 663, a28: 1 },
  { num: 664, a28: 1 },
  { num: 665, a28: 0 },
  { num: 666, a28: 0 },
  { num: 667, a28: 0 },
  { num: 668, a28: 0 },
  { num: 669, a28: -1 },
];

const data2 = [
  { num: 1, g28: 0 },
  { num: 2, g28: 0 },
  { num: 3, g28: 0 },
  { num: 4, g28: 0 },
  { num: 5, g28: 0 },
  { num: 6, g28: 0 },
  { num: 7, g28: -1 },
  { num: 8, g28: 0 },
  { num: 9, g28: 0 },
  { num: 10, g28: 0 },
  { num: 11, g28: 0 },
  { num: 12, g28: -1 },
  { num: 13, g28: -1 },
  { num: 14, g28: -1 },
  { num: 15, g28: -1 },
  { num: 16, g28: -1 },
  { num: 17, g28: -1 },
  { num: 18, g28: -1 },
  { num: 19, g28: 0 },
  { num: 20, g28: -1 },
  { num: 21, g28: -1 },
  { num: 22, g28: -1 },
  { num: 23, g28: 0 },
  { num: 24, g28: 0 },
  { num: 25, g28: 0 },
  { num: 26, g28: 0 },
  { num: 27, g28: 0 },
  { num: 28, g28: 1 },
  { num: 29, g28: 1 },
  { num: 30, g28: 2 },
  { num: 31, g28: 3 },
  { num: 32, g28: 4 },
  { num: 33, g28: 7 },
  { num: 34, g28: 10 },
  { num: 35, g28: 22 },
  { num: 36, g28: 49 },
  { num: 37, g28: 51 },
  { num: 38, g28: 39 },
  { num: 39, g28: 22 },
  { num: 40, g28: 0 },
  { num: 41, g28: -35 },
  { num: 42, g28: -52 },
  { num: 43, g28: -70 },
  { num: 44, g28: -85 },
  { num: 45, g28: -67 },
  { num: 46, g28: -50 },
  { num: 47, g28: -36 },
  { num: 48, g28: -1 },
  { num: 49, g28: 21 },
  { num: 50, g28: 39 },
  { num: 51, g28: 66 },
  { num: 52, g28: 71 },
  { num: 53, g28: 62 },
  { num: 54, g28: 55 },
  { num: 55, g28: 29 },
  { num: 56, g28: 6 },
  { num: 57, g28: -10 },
  { num: 58, g28: -38 },
  { num: 59, g28: -52 },
  { num: 60, g28: -51 },
  { num: 61, g28: -56 },
  { num: 62, g28: -47 },
  { num: 63, g28: -24 },
  { num: 64, g28: -13 },
  { num: 65, g28: 8 },
  { num: 66, g28: 32 },
  { num: 67, g28: 37 },
  { num: 68, g28: 47 },
  { num: 69, g28: 50 },
  { num: 70, g28: 35 },
  { num: 71, g28: 23 },
  { num: 72, g28: 9 },
  { num: 73, g28: -11 },
  { num: 74, g28: -21 },
  { num: 75, g28: -30 },
  { num: 76, g28: -41 },
  { num: 77, g28: -38 },
  { num: 78, g28: -29 },
  { num: 79, g28: -23 },
  { num: 80, g28: -4 },
  { num: 81, g28: 11 },
  { num: 82, g28: 17 },
  { num: 83, g28: 29 },
  { num: 84, g28: 31 },
  { num: 85, g28: 27 },
  { num: 86, g28: 24 },
  { num: 87, g28: 11 },
  { num: 88, g28: -1 },
  { num: 89, g28: -9 },
  { num: 90, g28: -20 },
  { num: 91, g28: -24 },
  { num: 92, g28: -24 },
  { num: 93, g28: -23 },
  { num: 94, g28: -16 },
  { num: 95, g28: -5 },
  { num: 96, g28: 2 },
  { num: 97, g28: 12 },
  { num: 98, g28: 20 },
  { num: 99, g28: 19 },
  { num: 100, g28: 20 },
  { num: 101, g28: 16 },
  { num: 102, g28: 6 },
  { num: 103, g28: 1 },
  { num: 104, g28: -7 },
  { num: 105, g28: -15 },
  { num: 106, g28: -17 },
  { num: 107, g28: -19 },
  { num: 108, g28: -17 },
  { num: 109, g28: -10 },
  { num: 110, g28: -4 },
  { num: 111, g28: 4 },
  { num: 112, g28: 11 },
  { num: 113, g28: 14 },
  { num: 114, g28: 16 },
  { num: 115, g28: 16 },
  { num: 116, g28: 10 },
  { num: 117, g28: 5 },
  { num: 118, g28: -1 },
  { num: 119, g28: -8 },
  { num: 120, g28: -12 },
  { num: 121, g28: -14 },
  { num: 122, g28: -16 },
  { num: 123, g28: -12 },
  { num: 124, g28: -8 },
  { num: 125, g28: -3 },
  { num: 126, g28: 5 },
  { num: 127, g28: 8 },
  { num: 128, g28: 10 },
  { num: 129, g28: 14 },
  { num: 130, g28: 12 },
  { num: 131, g28: 8 },
  { num: 132, g28: 4 },
  { num: 133, g28: -2 },
  { num: 134, g28: -6 },
  { num: 135, g28: -9 },
  { num: 136, g28: -12 },
  { num: 137, g28: -11 },
  { num: 138, g28: -9 },
  { num: 139, g28: -6 },
  { num: 140, g28: -1 },
  { num: 141, g28: 3 },
  { num: 142, g28: 7 },
  { num: 143, g28: 10 },
  { num: 144, g28: 9 },
  { num: 145, g28: 8 },
  { num: 146, g28: 6 },
  { num: 147, g28: 1 },
  { num: 148, g28: -3 },
  { num: 149, g28: -6 },
  { num: 150, g28: -9 },
  { num: 151, g28: -9 },
  { num: 152, g28: -8 },
  { num: 153, g28: -6 },
  { num: 154, g28: -3 },
  { num: 155, g28: 0 },
  { num: 156, g28: 4 },
  { num: 157, g28: 6 },
  { num: 158, g28: 7 },
  { num: 159, g28: 7 },
  { num: 160, g28: 5 },
  { num: 161, g28: 2 },
  { num: 162, g28: 0 },
  { num: 163, g28: -3 },
  { num: 164, g28: -5 },
  { num: 165, g28: -6 },
  { num: 166, g28: -6 },
  { num: 167, g28: -5 },
  { num: 168, g28: -4 },
  { num: 169, g28: -1 },
  { num: 170, g28: 1 },
  { num: 171, g28: 3 },
  { num: 172, g28: 4 },
  { num: 173, g28: 4 },
  { num: 174, g28: 4 },
  { num: 175, g28: 3 },
  { num: 176, g28: 1 },
  { num: 177, g28: -1 },
  { num: 178, g28: -4 },
  { num: 179, g28: -4 },
  { num: 180, g28: -4 },
  { num: 181, g28: -4 },
  { num: 182, g28: -3 },
  { num: 183, g28: -2 },
  { num: 184, g28: 1 },
  { num: 185, g28: 2 },
  { num: 186, g28: 3 },
  { num: 187, g28: 2 },
  { num: 188, g28: 2 },
  { num: 189, g28: 1 },
  { num: 190, g28: 0 },
  { num: 191, g28: -1 },
  { num: 192, g28: -3 },
  { num: 193, g28: -3 },
  { num: 194, g28: -3 },
  { num: 195, g28: -3 },
  { num: 196, g28: -1 },
  { num: 197, g28: 0 },
  { num: 198, g28: 1 },
  { num: 199, g28: 2 },
  { num: 200, g28: 2 },
  { num: 201, g28: 2 },
  { num: 202, g28: 1 },
  { num: 203, g28: 0 },
  { num: 204, g28: -1 },
  { num: 205, g28: -2 },
  { num: 206, g28: -3 },
  { num: 207, g28: -3 },
  { num: 208, g28: -2 },
  { num: 209, g28: -2 },
  { num: 210, g28: -1 },
  { num: 211, g28: 0 },
  { num: 212, g28: 1 },
  { num: 213, g28: 2 },
  { num: 214, g28: 3 },
  { num: 215, g28: 2 },
  { num: 216, g28: 1 },
  { num: 217, g28: 0 },
  { num: 218, g28: -3 },
  { num: 219, g28: -2 },
  { num: 220, g28: -3 },
  { num: 221, g28: -4 },
  { num: 222, g28: -3 },
  { num: 223, g28: -2 },
  { num: 224, g28: -1 },
  { num: 225, g28: 1 },
  { num: 226, g28: 1 },
  { num: 227, g28: 3 },
  { num: 228, g28: 3 },
  { num: 229, g28: 2 },
  { num: 230, g28: 2 },
  { num: 231, g28: 0 },
  { num: 232, g28: -2 },
  { num: 233, g28: -3 },
  { num: 234, g28: -4 },
  { num: 235, g28: -4 },
  { num: 236, g28: -4 },
  { num: 237, g28: -4 },
  { num: 238, g28: -2 },
  { num: 239, g28: 0 },
  { num: 240, g28: 1 },
  { num: 241, g28: 2 },
  { num: 242, g28: 4 },
  { num: 243, g28: 3 },
  { num: 244, g28: 2 },
  { num: 245, g28: 2 },
  { num: 246, g28: 0 },
  { num: 247, g28: -2 },
  { num: 248, g28: -3 },
  { num: 249, g28: -4 },
  { num: 250, g28: -4 },
  { num: 251, g28: -4 },
  { num: 252, g28: -3 },
  { num: 253, g28: -1 },
  { num: 254, g28: 0 },
  { num: 255, g28: 1 },
  { num: 256, g28: 2 },
  { num: 257, g28: 3 },
  { num: 258, g28: 3 },
  { num: 259, g28: 2 },
  { num: 260, g28: 1 },
  { num: 261, g28: 0 },
  { num: 262, g28: -2 },
  { num: 263, g28: -3 },
  { num: 264, g28: -4 },
  { num: 265, g28: -4 },
  { num: 266, g28: -4 },
  { num: 267, g28: -2 },
  { num: 268, g28: -1 },
  { num: 269, g28: 0 },
  { num: 270, g28: 2 },
  { num: 271, g28: 2 },
  { num: 272, g28: 3 },
  { num: 273, g28: 3 },
  { num: 274, g28: 2 },
  { num: 275, g28: 1 },
  { num: 276, g28: 0 },
  { num: 277, g28: -2 },
  { num: 278, g28: -3 },
  { num: 279, g28: -3 },
  { num: 280, g28: -4 },
  { num: 281, g28: -3 },
  { num: 282, g28: -2 },
  { num: 283, g28: -1 },
  { num: 284, g28: 1 },
  { num: 285, g28: 2 },
  { num: 286, g28: 2 },
  { num: 287, g28: 2 },
  { num: 288, g28: 2 },
  { num: 289, g28: 1 },
  { num: 290, g28: 0 },
  { num: 291, g28: -1 },
  { num: 292, g28: -2 },
  { num: 293, g28: -2 },
  { num: 294, g28: -3 },
  { num: 295, g28: -3 },
  { num: 296, g28: -2 },
  { num: 297, g28: -2 },
  { num: 298, g28: 0 },
  { num: 299, g28: 0 },
  { num: 300, g28: 1 },
  { num: 301, g28: 2 },
  { num: 302, g28: 2 },
  { num: 303, g28: 1 },
  { num: 304, g28: 1 },
  { num: 305, g28: 0 },
  { num: 306, g28: -1 },
  { num: 307, g28: -1 },
  { num: 308, g28: -2 },
  { num: 309, g28: -2 },
  { num: 310, g28: -2 },
  { num: 311, g28: -2 },
  { num: 312, g28: -2 },
  { num: 313, g28: 0 },
  { num: 314, g28: 0 },
  { num: 315, g28: 0 },
  { num: 316, g28: 1 },
  { num: 317, g28: 1 },
  { num: 318, g28: 1 },
  { num: 319, g28: 0 },
  { num: 320, g28: 0 },
  { num: 321, g28: 0 },
  { num: 322, g28: 0 },
  { num: 323, g28: -1 },
  { num: 324, g28: -1 },
  { num: 325, g28: -2 },
  { num: 326, g28: -2 },
  { num: 327, g28: -1 },
  { num: 328, g28: -1 },
  { num: 329, g28: -1 },
  { num: 330, g28: 0 },
  { num: 331, g28: 0 },
  { num: 332, g28: 0 },
  { num: 333, g28: 0 },
  { num: 334, g28: 0 },
  { num: 335, g28: 0 },
  { num: 336, g28: 0 },
  { num: 337, g28: 0 },
  { num: 338, g28: 0 },
  { num: 339, g28: -1 },
  { num: 340, g28: -1 },
  { num: 341, g28: -1 },
  { num: 342, g28: -1 },
  { num: 343, g28: -1 },
  { num: 344, g28: -1 },
  { num: 345, g28: 0 },
  { num: 346, g28: -1 },
  { num: 347, g28: 0 },
  { num: 348, g28: 0 },
  { num: 349, g28: 0 },
  { num: 350, g28: 0 },
  { num: 351, g28: 0 },
  { num: 352, g28: 0 },
  { num: 353, g28: 0 },
  { num: 354, g28: -1 },
  { num: 355, g28: -1 },
  { num: 356, g28: -1 },
  { num: 357, g28: -1 },
  { num: 358, g28: -1 },
  { num: 359, g28: -1 },
  { num: 360, g28: -1 },
  { num: 361, g28: -1 },
  { num: 362, g28: 0 },
  { num: 363, g28: 0 },
  { num: 364, g28: 0 },
  { num: 365, g28: 0 },
  { num: 366, g28: 0 },
  { num: 367, g28: 0 },
  { num: 368, g28: 0 },
  { num: 369, g28: 0 },
  { num: 370, g28: 0 },
  { num: 371, g28: -1 },
  { num: 372, g28: -2 },
  { num: 373, g28: -1 },
  { num: 374, g28: -2 },
  { num: 375, g28: -1 },
  { num: 376, g28: 0 },
  { num: 377, g28: 0 },
  { num: 378, g28: 0 },
  { num: 379, g28: 0 },
  { num: 380, g28: 0 },
  { num: 381, g28: 1 },
  { num: 382, g28: 0 },
  { num: 383, g28: 0 },
  { num: 384, g28: 0 },
  { num: 385, g28: -1 },
  { num: 386, g28: -1 },
  { num: 387, g28: -1 },
  { num: 388, g28: -2 },
  { num: 389, g28: -1 },
  { num: 390, g28: -1 },
  { num: 391, g28: -1 },
  { num: 392, g28: -1 },
  { num: 393, g28: 0 },
  { num: 394, g28: 0 },
  { num: 395, g28: 1 },
  { num: 396, g28: 1 },
  { num: 397, g28: 1 },
  { num: 398, g28: 0 },
  { num: 399, g28: 0 },
  { num: 400, g28: -1 },
  { num: 401, g28: -1 },
  { num: 402, g28: -1 },
  { num: 403, g28: -2 },
  { num: 404, g28: -1 },
  { num: 405, g28: -2 },
  { num: 406, g28: -1 },
  { num: 407, g28: 0 },
  { num: 408, g28: 0 },
  { num: 409, g28: 0 },
  { num: 410, g28: 1 },
  { num: 411, g28: 0 },
  { num: 412, g28: 0 },
  { num: 413, g28: 0 },
  { num: 414, g28: 0 },
  { num: 415, g28: 0 },
  { num: 416, g28: -1 },
  { num: 417, g28: -1 },
  { num: 418, g28: -1 },
  { num: 419, g28: -1 },
  { num: 420, g28: -1 },
  { num: 421, g28: 0 },
  { num: 422, g28: -1 },
  { num: 423, g28: 0 },
  { num: 424, g28: 0 },
  { num: 425, g28: 0 },
  { num: 426, g28: 0 },
  { num: 427, g28: 0 },
  { num: 428, g28: 0 },
  { num: 429, g28: 0 },
  { num: 430, g28: 0 },
  { num: 431, g28: -1 },
  { num: 432, g28: -1 },
  { num: 433, g28: -1 },
  { num: 434, g28: -1 },
  { num: 435, g28: -1 },
  { num: 436, g28: -1 },
  { num: 437, g28: -1 },
  { num: 438, g28: 0 },
  { num: 439, g28: 0 },
  { num: 440, g28: 0 },
  { num: 441, g28: 0 },
  { num: 442, g28: 0 },
  { num: 443, g28: 0 },
  { num: 444, g28: 0 },
  { num: 445, g28: 0 },
  { num: 446, g28: -1 },
  { num: 447, g28: -1 },
  { num: 448, g28: -1 },
  { num: 449, g28: -1 },
  { num: 450, g28: -1 },
  { num: 451, g28: -1 },
  { num: 452, g28: -1 },
  { num: 453, g28: 0 },
  { num: 454, g28: 0 },
  { num: 455, g28: 0 },
  { num: 456, g28: 0 },
  { num: 457, g28: 0 },
  { num: 458, g28: 0 },
  { num: 459, g28: 0 },
  { num: 460, g28: -1 },
  { num: 461, g28: -1 },
  { num: 462, g28: -1 },
  { num: 463, g28: -1 },
  { num: 464, g28: -1 },
  { num: 465, g28: -1 },
  { num: 466, g28: -1 },
  { num: 467, g28: -1 },
  { num: 468, g28: -1 },
  { num: 469, g28: 0 },
  { num: 470, g28: 0 },
  { num: 471, g28: 0 },
  { num: 472, g28: 0 },
  { num: 473, g28: 0 },
  { num: 474, g28: 0 },
  { num: 475, g28: 0 },
  { num: 476, g28: -1 },
  { num: 477, g28: 0 },
  { num: 478, g28: -1 },
  { num: 479, g28: -1 },
  { num: 480, g28: -1 },
  { num: 481, g28: 0 },
  { num: 482, g28: -1 },
  { num: 483, g28: -1 },
  { num: 484, g28: 0 },
  { num: 485, g28: 0 },
  { num: 486, g28: 0 },
  { num: 487, g28: 0 },
  { num: 488, g28: 0 },
  { num: 489, g28: 0 },
  { num: 490, g28: 0 },
  { num: 491, g28: 0 },
  { num: 492, g28: 0 },
  { num: 493, g28: 0 },
  { num: 494, g28: -1 },
  { num: 495, g28: -1 },
  { num: 496, g28: -1 },
  { num: 497, g28: -1 },
  { num: 498, g28: -1 },
  { num: 499, g28: -1 },
  { num: 500, g28: 0 },
  { num: 501, g28: 0 },
  { num: 502, g28: 0 },
  { num: 503, g28: 0 },
  { num: 504, g28: 0 },
  { num: 505, g28: 0 },
  { num: 506, g28: 0 },
  { num: 507, g28: 0 },
  { num: 508, g28: 0 },
  { num: 509, g28: -1 },
  { num: 510, g28: -1 },
  { num: 511, g28: -1 },
  { num: 512, g28: -1 },
  { num: 513, g28: -1 },
  { num: 514, g28: 0 },
  { num: 515, g28: -1 },
  { num: 516, g28: 0 },
  { num: 517, g28: 0 },
  { num: 518, g28: 0 },
  { num: 519, g28: 0 },
  { num: 520, g28: 0 },
  { num: 521, g28: 0 },
  { num: 522, g28: 0 },
  { num: 523, g28: 0 },
  { num: 524, g28: -1 },
  { num: 525, g28: -1 },
  { num: 526, g28: -1 },
  { num: 527, g28: -1 },
  { num: 528, g28: -1 },
  { num: 529, g28: -1 },
  { num: 530, g28: 0 },
  { num: 531, g28: 0 },
  { num: 532, g28: 0 },
  { num: 533, g28: 0 },
  { num: 534, g28: 0 },
  { num: 535, g28: 0 },
  { num: 536, g28: 0 },
  { num: 537, g28: 0 },
  { num: 538, g28: -1 },
  { num: 539, g28: -1 },
  { num: 540, g28: -1 },
  { num: 541, g28: -1 },
  { num: 542, g28: -1 },
  { num: 543, g28: -1 },
  { num: 544, g28: -1 },
  { num: 545, g28: -1 },
  { num: 546, g28: 0 },
  { num: 547, g28: 0 },
  { num: 548, g28: 0 },
  { num: 549, g28: 0 },
  { num: 550, g28: 0 },
  { num: 551, g28: 0 },
  { num: 552, g28: 0 },
  { num: 553, g28: 0 },
  { num: 554, g28: -1 },
  { num: 555, g28: -1 },
  { num: 556, g28: -1 },
  { num: 557, g28: -1 },
  { num: 558, g28: -1 },
  { num: 559, g28: -1 },
  { num: 560, g28: -1 },
  { num: 561, g28: 0 },
  { num: 562, g28: 0 },
  { num: 563, g28: 0 },
  { num: 564, g28: 0 },
  { num: 565, g28: 0 },
  { num: 566, g28: 0 },
  { num: 567, g28: 0 },
  { num: 568, g28: -1 },
  { num: 569, g28: -1 },
  { num: 570, g28: -1 },
  { num: 571, g28: -1 },
  { num: 572, g28: -1 },
  { num: 573, g28: -1 },
  { num: 574, g28: -1 },
  { num: 575, g28: 0 },
  { num: 576, g28: -1 },
  { num: 577, g28: 0 },
  { num: 578, g28: 0 },
  { num: 579, g28: 0 },
  { num: 580, g28: 0 },
  { num: 581, g28: 0 },
  { num: 582, g28: -1 },
  { num: 583, g28: -1 },
  { num: 584, g28: -1 },
  { num: 585, g28: -1 },
  { num: 586, g28: 0 },
  { num: 587, g28: -1 },
  { num: 588, g28: -1 },
  { num: 589, g28: 0 },
  { num: 590, g28: -1 },
  { num: 591, g28: 0 },
  { num: 592, g28: 0 },
  { num: 593, g28: 0 },
  { num: 594, g28: 0 },
  { num: 595, g28: 0 },
  { num: 596, g28: 0 },
  { num: 597, g28: 0 },
  { num: 598, g28: 0 },
  { num: 599, g28: -1 },
  { num: 600, g28: -1 },
  { num: 601, g28: -1 },
  { num: 602, g28: -1 },
  { num: 603, g28: -1 },
  { num: 604, g28: 0 },
  { num: 605, g28: 0 },
  { num: 606, g28: 0 },
  { num: 607, g28: 0 },
  { num: 608, g28: 0 },
  { num: 609, g28: 0 },
  { num: 610, g28: 0 },
  { num: 611, g28: -1 },
  { num: 612, g28: 0 },
  { num: 613, g28: 0 },
  { num: 614, g28: -1 },
  { num: 615, g28: -1 },
  { num: 616, g28: -1 },
  { num: 617, g28: -1 },
  { num: 618, g28: 0 },
  { num: 619, g28: 0 },
  { num: 620, g28: 0 },
  { num: 621, g28: 0 },
  { num: 622, g28: 0 },
  { num: 623, g28: 0 },
  { num: 624, g28: 0 },
  { num: 625, g28: 0 },
  { num: 626, g28: -1 },
  { num: 627, g28: 0 },
  { num: 628, g28: 0 },
  { num: 629, g28: 0 },
  { num: 630, g28: -1 },
  { num: 631, g28: -1 },
  { num: 632, g28: 0 },
  { num: 633, g28: -1 },
  { num: 634, g28: -1 },
  { num: 635, g28: 0 },
  { num: 636, g28: 0 },
  { num: 637, g28: 0 },
  { num: 638, g28: 0 },
  { num: 639, g28: 0 },
  { num: 640, g28: 0 },
  { num: 641, g28: 0 },
  { num: 642, g28: 0 },
  { num: 643, g28: -1 },
  { num: 644, g28: -1 },
  { num: 645, g28: -1 },
  { num: 646, g28: 0 },
  { num: 647, g28: 0 },
  { num: 648, g28: 0 },
  { num: 649, g28: -1 },
  { num: 650, g28: 0 },
  { num: 651, g28: 0 },
  { num: 652, g28: -1 },
  { num: 653, g28: 1 },
  { num: 654, g28: 0 },
  { num: 655, g28: 0 },
  { num: 656, g28: -1 },
  { num: 657, g28: 0 },
  { num: 658, g28: 0 },
  { num: 659, g28: 0 },
  { num: 660, g28: 0 },
  { num: 661, g28: 0 },
  { num: 662, g28: 0 },
  { num: 663, g28: -1 },
  { num: 664, g28: -1 },
  { num: 665, g28: 0 },
  { num: 666, g28: 0 },
  { num: 667, g28: 0 },
  { num: 668, g28: 0 },
  { num: 669, g28: 0 },
];
export default Data;
