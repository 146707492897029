import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "../../components/Core/Button";
import imgHeroBg from "../../assets/image/png/ocean-splash.png";

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="dark-mode-texts pt-19 pt-lg-29 pb-35  bg-image mt-19 rounded-5 mx-md-6"
        css={`
          background-image: url(${imgHeroBg});
        `}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="8">
              <div className="text-center">
                <p className="gr-text-12 gr-text-color font-weight-bold text-uppercase mb-9">
                  Patented Marine Construction Technology
                </p>
                <h1 className="gr-text-2 mb-8">
                  Eliminate chatter & vibration using non-Newtonian materials.
                </h1>
                <div className="hero-btns mt-10">
                  <a
                    href="https://pdfpiw.uspto.gov/.piw?PageNum=0&docid=10457355&IDKey=46502987623D%0D%0A&HomeUrl=http%3A%2F%2Fpatft.uspto.gov%2Fnetacgi%2Fnph-Parser%3FSect1%3DPTO2%2526Sect2%3DHITOFF%2526p%3D1%2526u%3D%25252Fnetahtml%25252FPTO%25252Fsearch-bool.html%2526r%3D1%2526f%3DG%2526l%3D50%2526co1%3DAND%2526d%3DPTXT%2526s1%3D15%25252F712%2C593%2526OS%3D15%2F712%2C593%2526RS%3D15%2F712%2C593"
                    target="_blank"
                  >
                    <Button className="with-icon gr-hover-y">
                      View the patent
                      <i className="icon icon-tail-right font-weight-bold ml-5"></i>
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;
