import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import imgC from "../../assets/image/png/surfboard_render.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section py-15 dark-mode-texts bg-blackish-blue">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg="6" xl="5" className="order-2">
              <div className="content-img text-right pr-15 pr-lg-0 pl-15 pl-lg-0">
                <img src={imgC} alt="" className="rounded-5 w-100 " />
              </div>
            </Col>
            <Col xs="10" lg="8" xl="7" className="pr-xl-20 order-1">
              <div className="section-title content-text mb-13">
                <h2 className="title gr-text-5 mb-6">
                  Ride harder. Surf faster.
                  <br />
                  Sail smoother.
                </h2>
                <p className="gr-text-9">
                  Our patented technology utilizes the shear thickening
                  properties of lightweight Non-Newtonian polymers incorporated
                  into hulls and composite layups.
                </p>
                <p className="gr-text-9">
                  The further you push our technology, the more energy &
                  vibration it will absorb while maintaining slower impulse flex
                  characteristics (most applicable to board sports, surfing,
                  etc).
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
