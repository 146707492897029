import React from "react";
import { Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing/Hero";
import Content1 from "../sections/landing/Content1";
import Content2 from "../sections/landing/Content2";
import Content3 from "../sections/landing/Content3";
import Content4 from "../sections/landing/Content4";
import CTA2 from "../sections/landing1/CTA2";
import Data from "../sections/landing/Data";

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <Content1 />
        <Content2 />
        <Data />
        <Content3 />
        <Content4 />
        <CTA2 />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
