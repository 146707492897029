import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import boatPlanks from "../../assets/image/png/boat-planks.png";
import boatSlice from "../../assets/image/png/boat-slice.png";

const Content = () => {
  return (
    <>
      {/* <!-- Content Area --> */}
      <div className="content-section dark-mode-texts bg-blackish-blue py-21 py-lg-12">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm="12" md="6" className="mb-9 mb-lg-0 order-lg-2">
              <div class="d-flex flex-row">
                <img className="h-100 mr-4" src={boatPlanks} alt="" />
                <img className="h-100" src={boatSlice} alt="" />
              </div>
            </Col>
            <Col sm="10" md="6" className="order-lg-1">
              <div className="content-text">
                <p className="gr-text-8 mb-8">
                  Since composites were first used in watercraft in the 1950s we
                  have seen steady advancement in materials and design.
                </p>
                <p className="gr-text-8 mb-8">But we are something new.</p>
                <h4 className="gr-text-5 mb-0 pr-5">
                  We are the next standard for watercraft performance.
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Content;
